<template>
  <h1>Unser Leitbild</h1>
  <div class="Image">
    <img src="../../assets/img/Leitbild-PMP.png" />
  </div>
</template>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.Image {
  text-align: center;
}
</style>
